import _core from "./core";
import _pageLoad from "./page-load";
import _scrollWatch from "./scroll-watch";
import _history from "./history";
import _button from "./button";
import _status from "./status";
var exports = {};

/*!
 * Infinite Scroll v4.0.1
 * Automatically add next page
 *
 * Licensed GPLv3 for open source use
 * or Infinite Scroll Commercial License for commercial use
 *
 * https://infinite-scroll.com
 * Copyright 2018-2020 Metafizzy
 */
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(_core, _pageLoad, _scrollWatch, _history, _button, _status);
  }
})(window, function factory(InfiniteScroll) {
  return InfiniteScroll;
});

export default exports;