import _core from "./core";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// status
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _core, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.InfiniteScroll, window.fizzyUIUtils);
  }
})(window, function factory(window, InfiniteScroll, utils) {
  let proto = InfiniteScroll.prototype; // InfiniteScroll.defaults.status = null;

  InfiniteScroll.create.status = function () {
    let statusElem = utils.getQueryElement((this || _global).options.status);
    if (!statusElem) return; // elements

    (this || _global).statusElement = statusElem;
    (this || _global).statusEventElements = {
      request: statusElem.querySelector(".infinite-scroll-request"),
      error: statusElem.querySelector(".infinite-scroll-error"),
      last: statusElem.querySelector(".infinite-scroll-last")
    }; // events

    this.on("request", (this || _global).showRequestStatus);
    this.on("error", (this || _global).showErrorStatus);
    this.on("last", (this || _global).showLastStatus);
    this.bindHideStatus("on");
  };

  proto.bindHideStatus = function (bindMethod) {
    let hideEvent = (this || _global).options.append ? "append" : "load";
    this[bindMethod](hideEvent, (this || _global).hideAllStatus);
  };

  proto.showRequestStatus = function () {
    this.showStatus("request");
  };

  proto.showErrorStatus = function () {
    this.showStatus("error");
  };

  proto.showLastStatus = function () {
    this.showStatus("last"); // prevent last then append event race condition from showing last status #706

    this.bindHideStatus("off");
  };

  proto.showStatus = function (eventName) {
    show((this || _global).statusElement);
    this.hideStatusEventElements();
    let eventElem = (this || _global).statusEventElements[eventName];
    show(eventElem);
  };

  proto.hideAllStatus = function () {
    hide((this || _global).statusElement);
    this.hideStatusEventElements();
  };

  proto.hideStatusEventElements = function () {
    for (let type in (this || _global).statusEventElements) {
      let eventElem = (this || _global).statusEventElements[type];
      hide(eventElem);
    }
  }; // --------------------------  -------------------------- //


  function hide(elem) {
    setDisplay(elem, "none");
  }

  function show(elem) {
    setDisplay(elem, "block");
  }

  function setDisplay(elem, value) {
    if (elem) {
      elem.style.display = value;
    }
  } // --------------------------  -------------------------- //


  return InfiniteScroll;
});

export default exports;