import _core from "./core";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// scroll-watch
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _core, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.InfiniteScroll, window.fizzyUIUtils);
  }
})(window, function factory(window, InfiniteScroll, utils) {
  let proto = InfiniteScroll.prototype; // default options

  Object.assign(InfiniteScroll.defaults, {
    scrollThreshold: 400 // elementScroll: null,

  });

  InfiniteScroll.create.scrollWatch = function () {
    // events
    (this || _global).pageScrollHandler = (this || _global).onPageScroll.bind(this || _global);
    (this || _global).resizeHandler = (this || _global).onResize.bind(this || _global);
    let scrollThreshold = (this || _global).options.scrollThreshold;
    let isEnable = scrollThreshold || scrollThreshold === 0;
    if (isEnable) this.enableScrollWatch();
  };

  InfiniteScroll.destroy.scrollWatch = function () {
    this.disableScrollWatch();
  };

  proto.enableScrollWatch = function () {
    if ((this || _global).isScrollWatching) return;
    (this || _global).isScrollWatching = true;
    this.updateMeasurements();
    this.updateScroller(); // TODO disable after error?

    this.on("last", (this || _global).disableScrollWatch);
    this.bindScrollWatchEvents(true);
  };

  proto.disableScrollWatch = function () {
    if (!(this || _global).isScrollWatching) return;
    this.bindScrollWatchEvents(false);
    delete (this || _global).isScrollWatching;
  };

  proto.bindScrollWatchEvents = function (isBind) {
    let addRemove = isBind ? "addEventListener" : "removeEventListener";

    (this || _global).scroller[addRemove]("scroll", (this || _global).pageScrollHandler);

    window[addRemove]("resize", (this || _global).resizeHandler);
  };

  proto.onPageScroll = InfiniteScroll.throttle(function () {
    let distance = this.getBottomDistance();

    if (distance <= (this || _global).options.scrollThreshold) {
      this.dispatchEvent("scrollThreshold");
    }
  });

  proto.getBottomDistance = function () {
    let bottom, scrollY;

    if ((this || _global).options.elementScroll) {
      bottom = (this || _global).scroller.scrollHeight;
      scrollY = (this || _global).scroller.scrollTop + (this || _global).scroller.clientHeight;
    } else {
      bottom = (this || _global).top + (this || _global).element.clientHeight;
      scrollY = window.scrollY + (this || _global).windowHeight;
    }

    return bottom - scrollY;
  };

  proto.onResize = function () {
    this.updateMeasurements();
  };

  utils.debounceMethod(InfiniteScroll, "onResize", 150); // --------------------------  -------------------------- //

  return InfiniteScroll;
});

export default exports;