import _evEmitter from "ev-emitter";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// core
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _evEmitter, _fizzyUiUtils);
  } else {
    // browser global
    window.InfiniteScroll = factory(window, window.EvEmitter, window.fizzyUIUtils);
  }
})(window, function factory(window, EvEmitter, utils) {
  let jQuery = window.jQuery; // internal store of all InfiniteScroll intances

  let instances = {};

  function InfiniteScroll(element, options) {
    let queryElem = utils.getQueryElement(element);

    if (!queryElem) {
      console.error("Bad element for InfiniteScroll: " + (queryElem || element));
      return;
    }

    element = queryElem; // do not initialize twice on same element

    if (element.infiniteScrollGUID) {
      let instance = instances[element.infiniteScrollGUID];
      instance.option(options);
      return instance;
    }

    (this || _global).element = element; // options

    (this || _global).options = { ...InfiniteScroll.defaults
    };
    this.option(options); // add jQuery

    if (jQuery) {
      (this || _global).$element = jQuery((this || _global).element);
    }

    this.create();
  } // defaults


  InfiniteScroll.defaults = {// path: null,
    // hideNav: null,
    // debug: false,
  }; // create & destroy methods

  InfiniteScroll.create = {};
  InfiniteScroll.destroy = {};
  let proto = InfiniteScroll.prototype; // inherit EvEmitter

  Object.assign(proto, EvEmitter.prototype); // --------------------------  -------------------------- //
  // globally unique identifiers

  let GUID = 0;

  proto.create = function () {
    // create core
    // add id for InfiniteScroll.data
    let id = (this || _global).guid = ++GUID;
    (this || _global).element.infiniteScrollGUID = id; // expando

    instances[id] = this || _global; // associate via id
    // properties

    (this || _global).pageIndex = 1; // default to first page

    (this || _global).loadCount = 0;
    this.updateGetPath(); // bail if getPath not set, or returns falsey #776

    let hasPath = (this || _global).getPath && this.getPath();

    if (!hasPath) {
      console.error("Disabling InfiniteScroll");
      return;
    }

    this.updateGetAbsolutePath();
    this.log("initialized", [(this || _global).element.className]);
    this.callOnInit(); // create features

    for (let method in InfiniteScroll.create) {
      InfiniteScroll.create[method].call(this || _global);
    }
  };

  proto.option = function (opts) {
    Object.assign((this || _global).options, opts);
  }; // call onInit option, used for binding events on init


  proto.callOnInit = function () {
    let onInit = (this || _global).options.onInit;

    if (onInit) {
      onInit.call(this || _global, this || _global);
    }
  }; // ----- events ----- //


  proto.dispatchEvent = function (type, event, args) {
    this.log(type, args);
    let emitArgs = event ? [event].concat(args) : args;
    this.emitEvent(type, emitArgs); // trigger jQuery event

    if (!jQuery || !(this || _global).$element) {
      return;
    } // namespace jQuery event


    type += ".infiniteScroll";
    let $event = type;

    if (event) {
      // create jQuery event

      /* eslint-disable-next-line new-cap */
      let jQEvent = jQuery.Event(event);
      jQEvent.type = type;
      $event = jQEvent;
    }

    (this || _global).$element.trigger($event, args);
  };

  let loggers = {
    initialized: className => `on ${className}`,
    request: path => `URL: ${path}`,
    load: (response, path) => `${response.title || ""}. URL: ${path}`,
    error: (error, path) => `${error}. URL: ${path}`,
    append: (response, path, items) => `${items.length} items. URL: ${path}`,
    last: (response, path) => `URL: ${path}`,
    history: (title, path) => `URL: ${path}`,
    pageIndex: function (index, origin) {
      return `current page determined to be: ${index} from ${origin}`;
    }
  }; // log events

  proto.log = function (type, args) {
    if (!(this || _global).options.debug) return;
    let message = `[InfiniteScroll] ${type}`;
    let logger = loggers[type];
    if (logger) message += ". " + logger.apply(this || _global, args);
    console.log(message);
  }; // -------------------------- methods used amoung features -------------------------- //


  proto.updateMeasurements = function () {
    (this || _global).windowHeight = window.innerHeight;

    let rect = (this || _global).element.getBoundingClientRect();

    (this || _global).top = rect.top + window.scrollY;
  };

  proto.updateScroller = function () {
    let elementScroll = (this || _global).options.elementScroll;

    if (!elementScroll) {
      // default, use window
      (this || _global).scroller = window;
      return;
    } // if true, set to element, otherwise use option


    (this || _global).scroller = elementScroll === true ? (this || _global).element : utils.getQueryElement(elementScroll);

    if (!(this || _global).scroller) {
      throw new Error(`Unable to find elementScroll: ${elementScroll}`);
    }
  }; // -------------------------- page path -------------------------- //


  proto.updateGetPath = function () {
    let optPath = (this || _global).options.path;

    if (!optPath) {
      console.error(`InfiniteScroll path option required. Set as: ${optPath}`);
      return;
    } // function


    let type = typeof optPath;

    if (type == "function") {
      (this || _global).getPath = optPath;
      return;
    } // template string: '/pages/{{#}}.html'


    let templateMatch = type == "string" && optPath.match("{{#}}");

    if (templateMatch) {
      this.updateGetPathTemplate(optPath);
      return;
    } // selector: '.next-page-selector'


    this.updateGetPathSelector(optPath);
  };

  proto.updateGetPathTemplate = function (optPath) {
    // set getPath with template string
    (this || _global).getPath = () => {
      let nextIndex = (this || _global).pageIndex + 1;
      return optPath.replace("{{#}}", nextIndex);
    }; // get pageIndex from location
    // convert path option into regex to look for pattern in location
    // escape query (?) in url, allows for parsing GET parameters


    let regexString = optPath.replace(/(\\\?|\?)/, "\\?").replace("{{#}}", "(\\d\\d?\\d?)");
    let templateRe = new RegExp(regexString);
    let match = location.href.match(templateRe);

    if (match) {
      (this || _global).pageIndex = parseInt(match[1], 10);
      this.log("pageIndex", [(this || _global).pageIndex, "template string"]);
    }
  };

  let pathRegexes = [// WordPress & Tumblr - example.com/page/2
  // Jekyll - example.com/page2
  /^(.*?\/?page\/?)(\d\d?\d?)(.*?$)/, // Drupal - example.com/?page=1
  /^(.*?\/?\?page=)(\d\d?\d?)(.*?$)/, // catch all, last occurence of a number
  /(.*?)(\d\d?\d?)(?!.*\d)(.*?$)/]; // try matching href to pathRegexes patterns

  let getPathParts = InfiniteScroll.getPathParts = function (href) {
    if (!href) return;

    for (let regex of pathRegexes) {
      let match = href.match(regex);

      if (match) {
        let [, begin, index, end] = match;
        return {
          begin,
          index,
          end
        };
      }
    }
  };

  proto.updateGetPathSelector = function (optPath) {
    // parse href of link: '.next-page-link'
    let hrefElem = document.querySelector(optPath);

    if (!hrefElem) {
      console.error(`Bad InfiniteScroll path option. Next link not found: ${optPath}`);
      return;
    }

    let href = hrefElem.getAttribute("href");
    let pathParts = getPathParts(href);

    if (!pathParts) {
      console.error(`InfiniteScroll unable to parse next link href: ${href}`);
      return;
    }

    let {
      begin,
      index,
      end
    } = pathParts;
    (this || _global).isPathSelector = true; // flag for checkLastPage()

    (this || _global).getPath = () => begin + ((this || _global).pageIndex + 1) + end; // get pageIndex from href


    (this || _global).pageIndex = parseInt(index, 10) - 1;
    this.log("pageIndex", [(this || _global).pageIndex, "next link"]);
  };

  proto.updateGetAbsolutePath = function () {
    let path = this.getPath(); // path doesn't start with http or /

    let isAbsolute = path.match(/^http/) || path.match(/^\//);

    if (isAbsolute) {
      (this || _global).getAbsolutePath = (this || _global).getPath;
      return;
    }

    let {
      pathname
    } = location; // query parameter #829. example.com/?pg=2

    let isQuery = path.match(/^\?/); // /foo/bar/index.html => /foo/bar

    let directory = pathname.substring(0, pathname.lastIndexOf("/"));
    let pathStart = isQuery ? pathname : directory + "/";

    (this || _global).getAbsolutePath = () => pathStart + this.getPath();
  }; // -------------------------- nav -------------------------- //
  // hide navigation


  InfiniteScroll.create.hideNav = function () {
    let nav = utils.getQueryElement((this || _global).options.hideNav);
    if (!nav) return;
    nav.style.display = "none";
    (this || _global).nav = nav;
  };

  InfiniteScroll.destroy.hideNav = function () {
    if ((this || _global).nav) (this || _global).nav.style.display = "";
  }; // -------------------------- destroy -------------------------- //


  proto.destroy = function () {
    this.allOff(); // remove all event listeners
    // call destroy methods

    for (let method in InfiniteScroll.destroy) {
      InfiniteScroll.destroy[method].call(this || _global);
    }

    delete (this || _global).element.infiniteScrollGUID;
    delete instances[(this || _global).guid]; // remove jQuery data. #807

    if (jQuery && (this || _global).$element) {
      jQuery.removeData((this || _global).element, "infiniteScroll");
    }
  }; // -------------------------- utilities -------------------------- //
  // https://remysharp.com/2010/07/21/throttling-function-calls


  InfiniteScroll.throttle = function (fn, threshold) {
    threshold = threshold || 200;
    let last, timeout;
    return function () {
      let now = +new Date();
      let args = arguments;

      let trigger = () => {
        last = now;
        fn.apply(this || _global, args);
      };

      if (last && now < last + threshold) {
        // hold on to it
        clearTimeout(timeout);
        timeout = setTimeout(trigger, threshold);
      } else {
        trigger();
      }
    };
  };

  InfiniteScroll.data = function (elem) {
    elem = utils.getQueryElement(elem);
    let id = elem && elem.infiniteScrollGUID;
    return id && instances[id];
  }; // set internal jQuery, for Webpack + jQuery v3


  InfiniteScroll.setJQuery = function (jqry) {
    jQuery = jqry;
  }; // -------------------------- setup -------------------------- //


  utils.htmlInit(InfiniteScroll, "infinite-scroll"); // add noop _init method for jQuery Bridget. #768

  proto._init = function () {};

  let {
    jQueryBridget
  } = window;

  if (jQuery && jQueryBridget) {
    jQueryBridget("infiniteScroll", InfiniteScroll, jQuery);
  } // --------------------------  -------------------------- //


  return InfiniteScroll;
});

export default exports;