import _core from "./core";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// page-load
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _core);
  } else {
    // browser global
    factory(window, window.InfiniteScroll);
  }
})(window, function factory(window, InfiniteScroll) {
  let proto = InfiniteScroll.prototype;
  Object.assign(InfiniteScroll.defaults, {
    // append: false,
    loadOnScroll: true,
    checkLastPage: true,
    responseBody: "text",
    domParseResponse: true // prefill: false,
    // outlayer: null,

  });

  InfiniteScroll.create.pageLoad = function () {
    (this || _global).canLoad = true;
    this.on("scrollThreshold", (this || _global).onScrollThresholdLoad);
    this.on("load", (this || _global).checkLastPage);

    if ((this || _global).options.outlayer) {
      this.on("append", (this || _global).onAppendOutlayer);
    }
  };

  proto.onScrollThresholdLoad = function () {
    if ((this || _global).options.loadOnScroll) this.loadNextPage();
  };

  let domParser = new DOMParser();

  proto.loadNextPage = function () {
    if ((this || _global).isLoading || !(this || _global).canLoad) return;
    let {
      responseBody,
      domParseResponse,
      fetchOptions
    } = (this || _global).options;
    let path = this.getAbsolutePath();
    (this || _global).isLoading = true;
    if (typeof fetchOptions == "function") fetchOptions = fetchOptions();
    let fetchPromise = fetch(path, fetchOptions).then(response => {
      if (!response.ok) {
        let error = new Error(response.statusText);
        this.onPageError(error, path, response);
        return {
          response
        };
      }

      return response[responseBody]().then(body => {
        let canDomParse = responseBody == "text" && domParseResponse;

        if (canDomParse) {
          body = domParser.parseFromString(body, "text/html");
        }

        if (response.status == 204) {
          this.lastPageReached(body, path);
          return {
            body,
            response
          };
        } else {
          return this.onPageLoad(body, path, response);
        }
      });
    }).catch(error => {
      this.onPageError(error, path);
    });
    this.dispatchEvent("request", null, [path, fetchPromise]);
    return fetchPromise;
  };

  proto.onPageLoad = function (body, path, response) {
    // done loading if not appending
    if (!(this || _global).options.append) {
      (this || _global).isLoading = false;
    }

    (this || _global).pageIndex++;
    (this || _global).loadCount++;
    this.dispatchEvent("load", null, [body, path, response]);
    return this.appendNextPage(body, path, response);
  };

  proto.appendNextPage = function (body, path, response) {
    let {
      append,
      responseBody,
      domParseResponse
    } = (this || _global).options; // do not append json

    let isDocument = responseBody == "text" && domParseResponse;
    if (!isDocument || !append) return {
      body,
      response
    };
    let items = body.querySelectorAll(append);
    let promiseValue = {
      body,
      response,
      items
    }; // last page hit if no items. #840

    if (!items || !items.length) {
      this.lastPageReached(body, path);
      return promiseValue;
    }

    let fragment = getItemsFragment(items);

    let appendReady = () => {
      this.appendItems(items, fragment);
      (this || _global).isLoading = false;
      this.dispatchEvent("append", null, [body, path, items, response]);
      return promiseValue;
    }; // TODO add hook for option to trigger appendReady


    if ((this || _global).options.outlayer) {
      return this.appendOutlayerItems(fragment, appendReady);
    } else {
      return appendReady();
    }
  };

  proto.appendItems = function (items, fragment) {
    if (!items || !items.length) return; // get fragment if not provided

    fragment = fragment || getItemsFragment(items);
    refreshScripts(fragment);

    (this || _global).element.appendChild(fragment);
  };

  function getItemsFragment(items) {
    // add items to fragment
    let fragment = document.createDocumentFragment();
    if (items) fragment.append(...items);
    return fragment;
  } // replace <script>s with copies so they load
  // <script>s added by InfiniteScroll will not load
  // similar to https://stackoverflow.com/questions/610995


  function refreshScripts(fragment) {
    let scripts = fragment.querySelectorAll("script");

    for (let script of scripts) {
      let freshScript = document.createElement("script"); // copy attributes

      let attrs = script.attributes;

      for (let attr of attrs) {
        freshScript.setAttribute(attr.name, attr.value);
      } // copy inner script code. #718, #782


      freshScript.innerHTML = script.innerHTML;
      script.parentNode.replaceChild(freshScript, script);
    }
  } // ----- outlayer ----- //


  proto.appendOutlayerItems = function (fragment, appendReady) {
    let imagesLoaded = InfiniteScroll.imagesLoaded || window.imagesLoaded;

    if (!imagesLoaded) {
      console.error("[InfiniteScroll] imagesLoaded required for outlayer option");
      (this || _global).isLoading = false;
      return;
    } // append once images loaded


    return new Promise(function (resolve) {
      imagesLoaded(fragment, function () {
        let bodyResponse = appendReady();
        resolve(bodyResponse);
      });
    });
  };

  proto.onAppendOutlayer = function (response, path, items) {
    (this || _global).options.outlayer.appended(items);
  }; // ----- checkLastPage ----- //
  // check response for next element


  proto.checkLastPage = function (body, path) {
    let {
      checkLastPage,
      path: pathOpt
    } = (this || _global).options;
    if (!checkLastPage) return; // if path is function, check if next path is truthy

    if (typeof pathOpt == "function") {
      let nextPath = this.getPath();

      if (!nextPath) {
        this.lastPageReached(body, path);
        return;
      }
    } // get selector from checkLastPage or path option


    let selector;

    if (typeof checkLastPage == "string") {
      selector = checkLastPage;
    } else if ((this || _global).isPathSelector) {
      // path option is selector string
      selector = pathOpt;
    } // check last page for selector
    // bail if no selector or not document response


    if (!selector || !body.querySelector) return; // check if response has selector

    let nextElem = body.querySelector(selector);
    if (!nextElem) this.lastPageReached(body, path);
  };

  proto.lastPageReached = function (body, path) {
    (this || _global).canLoad = false;
    this.dispatchEvent("last", null, [body, path]);
  }; // ----- error ----- //


  proto.onPageError = function (error, path, response) {
    (this || _global).isLoading = false;
    (this || _global).canLoad = false;
    this.dispatchEvent("error", null, [error, path, response]);
    return error;
  }; // -------------------------- prefill -------------------------- //


  InfiniteScroll.create.prefill = function () {
    if (!(this || _global).options.prefill) return;
    let append = (this || _global).options.append;

    if (!append) {
      console.error(`append option required for prefill. Set as :${append}`);
      return;
    }

    this.updateMeasurements();
    this.updateScroller();
    (this || _global).isPrefilling = true;
    this.on("append", (this || _global).prefill);
    this.once("error", (this || _global).stopPrefill);
    this.once("last", (this || _global).stopPrefill);
    this.prefill();
  };

  proto.prefill = function () {
    let distance = this.getPrefillDistance();
    (this || _global).isPrefilling = distance >= 0;

    if ((this || _global).isPrefilling) {
      this.log("prefill");
      this.loadNextPage();
    } else {
      this.stopPrefill();
    }
  };

  proto.getPrefillDistance = function () {
    // element scroll
    if ((this || _global).options.elementScroll) {
      return (this || _global).scroller.clientHeight - (this || _global).scroller.scrollHeight;
    } // window


    return (this || _global).windowHeight - (this || _global).element.clientHeight;
  };

  proto.stopPrefill = function () {
    this.log("stopPrefill");
    this.off("append", (this || _global).prefill);
  }; // --------------------------  -------------------------- //


  return InfiniteScroll;
});

export default exports;