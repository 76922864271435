import _core from "./core";
import _fizzyUiUtils from "fizzy-ui-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// button
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _core, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.InfiniteScroll, window.fizzyUIUtils);
  }
})(window, function factory(window, InfiniteScroll, utils) {
  // -------------------------- InfiniteScrollButton -------------------------- //
  class InfiniteScrollButton {
    constructor(element, infScroll) {
      this.element = element;
      this.infScroll = infScroll; // events

      this.clickHandler = this.onClick.bind(this);
      this.element.addEventListener("click", this.clickHandler);
      infScroll.on("request", this.disable.bind(this));
      infScroll.on("load", this.enable.bind(this));
      infScroll.on("error", this.hide.bind(this));
      infScroll.on("last", this.hide.bind(this));
    }

    onClick(event) {
      event.preventDefault();
      this.infScroll.loadNextPage();
    }

    enable() {
      this.element.removeAttribute("disabled");
    }

    disable() {
      this.element.disabled = "disabled";
    }

    hide() {
      this.element.style.display = "none";
    }

    destroy() {
      this.element.removeEventListener("click", this.clickHandler);
    }

  } // -------------------------- InfiniteScroll methods -------------------------- //
  // InfiniteScroll.defaults.button = null;


  InfiniteScroll.create.button = function () {
    let buttonElem = utils.getQueryElement((this || _global).options.button);

    if (buttonElem) {
      (this || _global).button = new InfiniteScrollButton(buttonElem, this || _global);
    }
  };

  InfiniteScroll.destroy.button = function () {
    if ((this || _global).button) (this || _global).button.destroy();
  }; // --------------------------  -------------------------- //


  InfiniteScroll.Button = InfiniteScrollButton;
  return InfiniteScroll;
});

export default exports;